<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-tab-panel
          :module_name="page.name"
          :record="record"
          @change="save()"
          @archive="archive()"
          :loading="loading"
          :model="page.model">
          <template v-slot:main_fields>
            <v-text-field :label="$store.getters.translate('name')" v-model="record.name">
              <template #label>{{ $store.getters.translate('name') }}<span class="red--text"><strong>*</strong></span></template>
            </v-text-field>
            <v-text-field :label="$store.getters.translate('description')" v-model="record.description"/>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-toolbar dense flat>
          <v-spacer/>
          <v-toolbar-title>
            {{ $store.getters.translate('permissions_for_current_role') }}
          </v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-divider/>
        <v-data-table
            :loading="tableLoading"
            :disabled="tableLoading"
            :headers="fields"
            :items="getRecords"
            class="elevation-1"
            item-key="id"
            :disable-pagination="true"
            :disable-sort="true"
            :hide-default-footer="true"
            :hide-default-header="true">
          <template v-slot:header="{ props: { headers } }">
            <thead class="v-data-table-header">
              <tr>
                <th
                    v-for="header in headers"
                    :key="header.value"
                    role="columnheader"
                    scope="col"
                    :aria-label="header.text"
                    :class="[header.text ==='entities' ? 'text-start' : 'text-center']">
                  <span style="height: 32px; line-height: 32px;">
                    {{ header.text }}
                  </span>
                  <template v-if="header.text !=='entities'">
                    <v-checkbox
                        class="text-center d-inline-block"
                        v-model="selectAll"
                        :value="header.text"
                        style="margin-top: 4px; height: 32px; transform: translateY(15%);"/>
                  </template>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <template v-for="column in item.columns">
                  <template v-if="column.type === 'text'">
                    <td :key="column.text+column.type">
                      {{ $lodash.truncate(column.label, { length: 40 }) }}
                    </td>
                  </template>
                  <template v-else>
                    <td :key="column.text+column.type">
                      <v-checkbox :class='["text-center",column.class]' v-model="column.selected" :value="column.id" style="margin-top: 4px; height: 32px"/>
                    </td>
                  </template>
                </template>
              </tr>
              <tr v-if="getRecords.length === 0">
                <td class="text-center grey--text" :colspan="fields.length + 1">
                  <span v-if="!tableLoading">{{ $store.getters.translate("nothing_found") }}</span>
                  <span v-else>{{ $store.getters.translate("loading") }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import lodash from "lodash";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseBreadcrumb,
    BaseTabPanel,
  },
  data() {
    return {
      selectAll: [],
      page: {
        title: this.$store.getters.translate("roles"),
        name: "roles",
        model:'role'
      },
      record: {
        all_permissions: [],
        permissions: [],
      },
      hiddenModels: [
        'template',
        'file',
      ],
      orderColumns:{
        'show' : 1,
        'create' : 2,
        'edit' : 3,
        'delete' : 4,
      },
      loading: false,
      mainLoading: false,
      tableLoading: false,
      fields: [
        {
          text: this.$store.getters.translate('entities'),
          value: 'entities',
          order: 0,
        },
      ],
      list: {
        actions: {
          items : [],
          isLoading : false,
        },
        entities: {
          items : [],
          isLoading : false,
        },
      },
      allowedPermissions: [],
      total_records: [],
      records: [],
      bulk_action: [],
      permission_names: [],
      selected_permissions: [],
      intId: null,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load: lodash.debounce(function () {
      this.run();
    }, 100),
    manageColumnSelection(target, status) {
      this.records.forEach(record => {
        record.columns.forEach(column => {
          if(column.type === 'checkbox') {
            if(column.action_name === target) {
              column.selected = status ? column.id : null;
            }
          }
        });
      });
    },
    checkAndActivateSelectAll(actionCounts) {
      for(let key in actionCounts) {
        if(Object.hasOwnProperty.call(actionCounts, key)) {
          if(actionCounts[key]) {
            this.selectAll = [...this.selectAll, key];
          }
        }
      }
    },
    getOrder(action) {
      switch (action) {
        case 'show' :
        case 'create' :
        case 'edit' :
        case 'delete' :
          return this.orderColumns[action];
        case 'first' :
          return 0;
        default :
          return 999;
      }
    },
    run() {
      this.loading = true;
      this.mainLoading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.$route.params.id)
        .then((response) => {
          this.loading = false;
          this.mainLoading = false;
          this.record = response.data;
          this.updateBulkActions();
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
      this.intId = setInterval(() => {
        if(!this.mainLoading) {
          this.tableLoading = true;
          this.list.actions.isLoading = true;
          this.list.entities.isLoading = true;
          this.$http
              .get(this.$store.getters.appUrl + "v2/custom_permission_actions")
              .then((response) => {
                this.loading = false;
                this.list.actions.items = response.data;
                const additionalFields = [];
                this.list.actions.items.forEach( item => {
                  additionalFields.push({
                    text: this.$store.getters.translate(item.name),
                    align: "center",
                    value: item.id,
                    order: this.getOrder(item.name),
                  })
                });
                this.list.actions.isLoading = false;
                this.fields = [...this.fields, ...this.$lodash.sortBy(additionalFields, item => item.order)];
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
          this.$http
              .get(this.$store.getters.appUrl + "v2/custom_permission_entities")
              .then((response) => {
                this.loading = false;
                this.list.entities.items = response.data;
                this.list.entities.isLoading = false;
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
          this.$http
              .get(this.$store.getters.appUrl + `v2/custom_permissions_by_role?id=${this.$route.params.id}`)
              .then((response) => {
                this.loading = false;
                const actionCounts = {};
                response.data.forEach( permission => {
                  if(permission.status) {
                    this.allowedPermissions.push(permission.id);
                  }
                  if(Object.hasOwnProperty.call(actionCounts, permission['action_name'])) {
                    if(!permission.status) {
                      actionCounts[permission['action_name']] = false;
                    }
                  } else {
                    actionCounts[permission['action_name']] = !!permission.status;
                  }
                  const newItem = {
                    type: 'checkbox',
                    ...permission,
                    'selected' : permission.status ? permission.id : null,
                    'order' : this.getOrder(permission['action_name']),
                  }
                  if (this.total_records[permission['entity_name']]) {
                    this.total_records[permission['entity_name']].push(newItem)
                  } else {
                    this.total_records[permission['entity_name']] = [newItem];
                  }
                });
                this.checkAndActivateSelectAll(actionCounts);
                this.list.entities.items.forEach(item => {
                  const newRecord = {
                    columns: [
                      {
                        type: 'text',
                        label: this.$store.getters.translate(item.name),
                        class: `cbx-${item.action_name}`,
                        text: item.name,
                        order: 0,
                      },
                      ... this.total_records[item.name] ? this.total_records[item.name] : [],
                    ],
                    ...item
                  };
                  newRecord.columns = this.$lodash.sortBy(newRecord.columns, item => item.order);
                  if(!this.hiddenModels.includes(newRecord.name)) {
                    this.records.push(newRecord);
                  }
                });
                this.list.actions.isLoading = false;
                this.list.entities.isLoading = false;
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
          clearInterval(this.intId);
        }
      })
    },
    updateAllowedPermissions(permissionsOnUpdate) {
      const {toDisallow, toAllow} = permissionsOnUpdate;

      this.allowedPermissions = [
        ...this.allowedPermissions.filter( id => !toDisallow.includes(id)),
        ...toAllow
      ];
    },
    getPermissionsChanges() {
      const toDisallow = [], toAllow = [];

      this.records.forEach( record => {
        record.columns.forEach( column => {
          if(column.type === 'checkbox'){
            if(this.allowedPermissions.includes(column.id)) {
              if(column.selected !== column.id) {
                toDisallow.push(column.id);
              }
            }else{
              if(column.selected === column.id) {
                toAllow.push(column.id);
              }
            }
          }
        } );
      } );

      return {
        toDisallow,
        toAllow,
      }
    },
    save() {
      if(!this.loading) {
        let usual_fields_required = [{name: 'name', mandatory: true}];
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, usual_fields_required)) {
          this.loading = true;
          const permissionsOnUpdate = this.getPermissionsChanges();
          this.$http
            .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, { ...this.record, ...permissionsOnUpdate })
            .then((response) => {
              this.loading = false;
              if (response.status == 200) {
                this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                this.updateBulkActions();
                this.updateAllowedPermissions(permissionsOnUpdate);
              } else {
                var errorMessage = response.data.message;
                var errors = Object.keys(response.data.message);
                this.$toasted.error([errorMessage[errors[0]]]);
              }
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
        }
      }
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    updateBulkActions() {
      let amount_of_permissions = this.permission_names.length;
      let permissions = this.record.permissions;
      var counter = {
        show: 0,
        create: 0,
        edit: 0,
        delete: 0,
      };
      this.permission_names.forEach(function (item) {
        if (permissions.includes("show_" + item)) {
          counter.show++;
        }
        if (permissions.includes("create_" + item)) {
          counter.create++;
        }
        if (permissions.includes("edit_" + item)) {
          counter.edit++;
        }
        if (permissions.includes("delete_" + item)) {
          counter.delete++;
        }
      });
      for (const [key, value] of Object.entries(counter)) {
        if (value == amount_of_permissions) {
          if (!this.bulk_action.includes(key)) {
            this.bulk_action.push(key);
          }
        } else if (this.bulk_action.includes(key)) {
          this.bulk_action.splice(this.bulk_action.indexOf(key), 1);
        }
      }
    },
    checkLoading() {
      this.tableLoading = !(!this.list.entities.isLoading && !this.list.actions.isLoading);
    },
    translate(list) {
      let result = [];
      list.forEach((row) => {
        result.push({
          label: this.$store.getters.translate(row),
          value: row,
        });
      });
      return this.$lodash.sortBy(result, "label");
    },
  },
  computed: {
    getRecords() {
      return this.records;
    },
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
  watch:{
    'list.entities.isLoading': function () {
      this.checkLoading();
    },
    'list.actions.isLoading': function () {
      this.checkLoading();
    },
    selectAll: function (newV, oldV) {
      let status = newV.length > oldV.length,
        difference = newV
          .filter(x => !oldV.includes(x))
          .concat(oldV.filter(x => !newV.includes(x)))
          .shift();
      this.manageColumnSelection(difference, status);
    },
  },
};
</script>

<style scoped>
  .text-center >>> .v-input__slot {
    align-items: center;
    justify-content: center;
  }
</style>